import React from 'react'
import styled from 'styled-components'

import { P, Ul } from '@allied-solutions/affinity'

import { formatDate } from '../../../functions'
import { useStore } from '../../../hooks'
import {
  Collateral,
  Confirmation,
  FileUploader,
  Name,
  Notice,
  Section,
} from '../../../components'

const StackedUl = styled(Ul)`
  li + li {
    margin-top: ${props => props.theme.space[5]};
  }
`

const Data = () => {
  const { state } = useStore()
  return (
    <>
      {state.borrower.ReferenceID && (
        <Section pt={0}>
          <Name color="hint" />
          <Collateral />
        </Section>
      )}
      {state.form && (
        <>
          <Section fullWidth>
            <P mb={0} typeStyle="heading" textAlign="left" as="h4">
              Insurance Information
            </P>
            <StackedUl mt={8}>
              <li>
                <P mb={0} display="block" typeStyle="label" textAlign="left">
                  Insurance Company Name
                </P>
                <P
                  mb={0}
                  display="block"
                  typeStyle="bodySmall"
                  textAlign="left"
                  color="gray600"
                >
                  {state.form.InsuranceCompanyName}
                </P>
              </li>
              <li>
                <P mb={0} display="block" typeStyle="label" textAlign="left">
                  Insurance Policy Number
                </P>
                <P
                  mb={0}
                  display="block"
                  typeStyle="bodySmall"
                  textAlign="left"
                  color="gray600"
                >
                  {state.form.PolicyNumber}
                </P>
              </li>
              {state.form.AgentName && (
                <li>
                  <P mb={0} display="block" typeStyle="label" textAlign="left">
                    Agent Name
                  </P>
                  <P
                    display="block"
                    typeStyle="bodySmall"
                    textAlign="left"
                    color="secondary"
                  >
                    {state.form.AgentName}
                  </P>
                </li>
              )}
              {state.form.AgentTelephone && (
                <li>
                  <P mb={0} display="block" typeStyle="label" textAlign="left">
                    Agent Phone Number
                  </P>
                  <P
                    mb={0}
                    display="block"
                    typeStyle="bodySmall"
                    textAlign="left"
                    color="secondary"
                  >
                    {state.form.AgentTelephone}
                  </P>
                </li>
              )}
            </StackedUl>
            <P mb={0} typeStyle="heading" textAlign="left" as="h4" mt={12}>
              Deductible Information
            </P>
            <StackedUl mt={8}>
              <li>
                <P mb={0} display="block" typeStyle="label" textAlign="left">
                  Comprehensive Deductible
                </P>
                <P
                  mb={0}
                  display="block"
                  typeStyle="bodySmall"
                  textAlign="left"
                  color="secondary"
                >
                  ${state.form.CompDeductible || '--'}
                </P>
              </li>
              <li>
                <P mb={0} display="block" typeStyle="label" textAlign="left">
                  Collision Deductible
                </P>
                <P
                  mb={0}
                  display="block"
                  typeStyle="bodySmall"
                  textAlign="left"
                  color="secondary"
                >
                  ${state.form.CollDeductible || '--'}
                </P>
              </li>
              {state.form.EffectiveDate && (
                <li>
                  <P mb={0} display="block" typeStyle="label" textAlign="left">
                    Policy Effective Date
                  </P>
                  <P
                    mb={0}
                    display="block"
                    typeStyle="bodySmall"
                    textAlign="left"
                    color="secondary"
                  >
                    {formatDate(state.form.EffectiveDate)}
                  </P>
                </li>
              )}
            </StackedUl>
          </Section>
          {!!state.form?.files?.length && (
            <Section heading="Uploaded Files">
              <FileUploader files={state.form.files}>
                <FileUploader.FilesList mt={9} allowRemove={false} />
              </FileUploader>
            </Section>
          )}
          {state.form.LienholderRadio === `false` && (
            <Section fullWidth pb={6}>
              <Notice>
                <P>Please call your insurance provider</P> to add your financial
                institution as the lienholder on your policy.
              </Notice>
            </Section>
          )}
        </>
      )}
    </>
  )
}

const ConfirmPage = ({ location }) => {
  return <Confirmation location={location} data={<Data />} />
}

export default ConfirmPage
